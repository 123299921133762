import React, { PropsWithChildren } from 'react'
import { match, NavLink } from 'react-router-dom'

interface IProps {
  to: string
  isActive?: (match: match, location: any) => boolean
}

const NavbarLink: React.FunctionComponent<PropsWithChildren<IProps>> = (
  props: PropsWithChildren<IProps>
) => (
  <NavLink className="navbar__list-anchor" activeClassName="selected" {...props}>
    {props.children}
  </NavLink>
)

export default NavbarLink
