interface IReducer {
  list: any[]
  total: number
  isFetching: boolean
  error: any
  filters: any
  localisations: any[]
}

const initialState: IReducer = {
  list: [],
  isFetching: false,
  total: 0,
  error: null,
  filters: null,
  localisations: [],
}

const transactionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_TRANSACTIONS': {
      return {
        ...state,
        isFetching: true,
        list: [],
        total: 0,
      }
    }
    case 'FETCHING_TRANSACTIONS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'RECEIVE_TRANSACTIONS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }
    case 'RESET_TRANSACTIONS_FILTERS': {
      return {
        ...state,
        filters: null,
      }
    }

    case 'SET_TRANSACTIONS_FILTERS': {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    }

    case 'REPLACE_TRANSACTIONS_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      }
    }

    case 'GET_LOCALISATION_NAME': {
      return {
        ...state,
        localisations: action.payload,
      }
    }
    case 'CANCEL_TRANSACTION_REQUESTED': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'CANCEL_TRANSACTION_SUCCESS': {
      return {
        ...state,
        list: state.list.map((transaction) =>
          transaction.id === action.payload.id ? { ...transaction, ...action.payload } : transaction
        ),
        isFetching: false,
      }
    }
    case 'CANCEL_TRANSACTION_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'CREATING_CREDIT': {
      return {
        ...state,
      }
    }
    case 'CREATED_CREDIT': {
      return {
        ...state,
      }
    }
    case 'CREATING_CREDIT_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'CREATING_DEBIT': {
      return {
        ...state,
      }
    }
    case 'CREATED_DEBIT': {
      return {
        ...state,
      }
    }
    case 'CREATING_DEBIT_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'UPDATING_TRANSACTION': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'UPDATED_TRANSACTION': {
      return {
        ...state,
        list: state.list.map((transaction) =>
          transaction.id === action.payload.id ? { ...transaction, ...action.payload } : transaction
        ),
        isFetching: false,
      }
    }
    case 'UPDATING_TRANSACTION_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default transactionsReducer
