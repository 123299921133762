interface IReducer {
  total: number
  list: any[]
  merchant: any
  merchantsByShopId: any[]
  merchantsByLocId: any[]
  searchedMerchantsList: any[]
  isFetching: boolean
  isFetchingReportingSchedules: boolean
  isSearchFetching: boolean
  isFetchingMerchantsByLoc: boolean
  error: any
  filters: any[]
  parameters: any
  reportingSchedules: any[]
}

const initialState: IReducer = {
  total: 0,
  list: [],
  merchant: null,
  merchantsByShopId: [],
  merchantsByLocId: [],
  searchedMerchantsList: [],
  isFetching: false,
  isFetchingReportingSchedules: false,
  isSearchFetching: false,
  isFetchingMerchantsByLoc: false,
  error: null,
  filters: [],
  parameters: {},
  reportingSchedules: [],
}

const merchantsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'BY_SHOPID_FETCHING': {
      return {
        ...state,
        Fetching: true,
      }
    }

    case 'SEARCH_FETCHING': {
      return {
        ...state,
        isSearchFetching: true,
      }
    }
    case 'FETCHING_MERCHANTS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'FETCH_MERCHANTS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'TOGGLE_MERCHANT_REQUESTED': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'TOGGLE_MERCHANT_SUCCESS': {
      return {
        ...state,
        list: state.list.map((merchant) =>
          merchant.id === action.payload.id ? action.payload : merchant
        ),
        isFetching: false,
      }
    }

    case 'TOGGLE_MERCHANT_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }

    case 'RECEIVE_MERCHANTS': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'FETCH_MERCHANT_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'FETCHING_MERCHANTS_BY_SHOPID': {
      return {
        ...state,
        isFetching: true,
        merchantsByShopId: [],
      }
    }
    case 'RECEIVE_MERCHANTS_BY_SHOPID': {
      return {
        ...state,
        isFetching: false,
        merchantsByShopId: action.payload.data,
      }
    }
    case 'FETCH_MERCHANTS_BY_SHOPID_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'FETCHING_MERCHANTS_BY_LOCALISATION': {
      return {
        ...state,
        isFetchingMerchantsByLoc: true,
      }
    }

    case 'RECEIVE_MERCHANTS_BY_LOCALISATION': {
      return {
        ...state,
        isFetchingMerchantsByLoc: false,
        merchantsByLocId: action.payload,
      }
    }

    case 'FETCH_MERCHANTS_BY_LOCALISATION_ERR': {
      return {
        ...state,
        isFetchingMerchantsByLoc: false,
        error: action.payload.data,
      }
    }
    case 'FETCHING_MERCHANTS_BY_MERCHANT': {
      return {
        ...state,
        isFetchingMerchantsByLoc: true,
      }
    }

    case 'RECEIVE_MERCHANTS_BY_MERCHANT': {
      return {
        ...state,
        isFetchingMerchantsByLoc: false,
        merchantsByLocId: action.payload,
      }
    }

    case 'FETCH_MERCHANTS_BY_MERCHANT_ERR': {
      return {
        ...state,
        isFetchingMerchantsByLoc: false,
        error: action.payload.data,
      }
    }

    case 'FETCHING_MERCHANT_BY_ID': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_MERCHANT_BY_ID': {
      return {
        ...state,
        isFetching: false,
        merchant: action.payload,
      }
    }

    case 'FETCH_MERCHANT_BY_ID_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload.data,
      }
    }

    case 'RECEIVE_MERCHANT': {
      return {
        ...state,
        isFetching: false,
        merchant: action.payload,
      }
    }

    case 'CLEAN_MERCHANT': {
      return {
        ...state,
        isFetching: false,
        merchant: null,
      }
    }

    case 'CLEAN_MERCHANTS_BY_LOC': {
      return {
        ...state,
        isFetching: false,
        merchantsByLocId: [],
      }
    }

    case 'RECEIVE_SEARCH_MERCHANTS': {
      return {
        ...state,
        isSearchFetching: false,
        searchedTotal: action.payload.total,
        searchedmerchantsList: action.payload.data,
      }
    }

    case 'DISPLAY_MERCHANTS_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      }
    }

    case 'RESET_MERCHANTS_FILTERS': {
      return {
        ...state,
        filters: [],
      }
    }

    case 'UPDATING_MERCHANT': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'UPDATED_MERCHANT': {
      return {
        ...state,
        isFetching: false,
        merchant: {
          ...state.merchant,
          ...action.payload,
          shop: state.merchant.shop,
        },
        list: state.list.map((merchant) =>
          merchant.id === action.payload.id ? action.payload : merchant
        ),
      }
    }

    case 'UPDATING_MERCHANT_ERROR': {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    }

    case 'CREATING_MERCHANT': {
      return {
        ...state,
      }
    }

    case 'CREATED_MERCHANT': {
      return {
        ...state,
        isFetching: false,
        total: state.total + 1,
        list: [action.payload, ...state.list],
      }
    }

    case 'CREATING_MERCHANT_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }

    case 'FETCHING_MERCHANT_PARAMETERS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_MERCHANT_PARAMETERS': {
      return {
        ...state,
        isFetching: false,
        parameters: action.payload,
      }
    }

    case 'FETCH_MERCHANT_PARAMETERS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload.data,
      }
    }

    case 'UPDATING_MERCHANT_PARAMETERS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'UPDATED_MERCHANT_PARAMETERS': {
      return {
        ...state,
        isFetching: false,
      }
    }

    case 'UPDATING_MERCHANT_PARAMETERS_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }

    case 'FETCHING_MERCHANT_REPORTING_SCHEDULE': {
      return {
        ...state,
        isFetchingReportingSchedules: true,
      }
    }

    case 'RECEIVE_MERCHANT_REPORTING_SCHEDULE': {
      return {
        ...state,
        isFetchingReportingSchedules: false,
        reportingSchedules: action.payload,
      }
    }

    case 'FETCH_MERCHANT_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
        isFetchingReportingSchedules: false,
        error: action.payload.data,
      }
    }

    case 'UPDATING_MERCHANT_REPORTING_SCHEDULE': {
      return {
        ...state,
      }
    }

    case 'UPDATED_MERCHANT_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedules: state.reportingSchedules.map((report) =>
          report.id === action.payload.id ? action.payload : report
        ),
      }
    }

    case 'UPDATING_MERCHANT_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
      }
    }

    case 'UPDATING_MERCHANT_CREDENTIALS': {
      return {
        ...state,
      }
    }

    case 'UPDATED_MERCHANT_CREDENTIALS': {
      return {
        ...state,
      }
    }

    case 'UPDATING_MERCHANT_CREDENTIALS_ERROR': {
      return {
        ...state,
      }
    }

    default: {
      return state
    }
  }
}

export default merchantsReducer
