import Store from 'store'

interface IUser {
  id: number
  email: string
  firstname: string
  lastname: string
  localisation_id: number | null
  roles: string[]
  shop_id: number | null
  shops: number[]
  type: string
}

const getRoles = () => {
  if (Array.isArray(Store.get('lh_user')?.roles)) {
    return Store.get('lh_user').roles
  }

  return null
}

const appUser = (): IUser => {
  if (Store.get('lh_user')) {
    return Store.get('lh_user')
  }

  return null
}

const getUserType = () => {
  return appUser()?.type
}

const isAdmin = () => {
  return getUserType() === 'admin'
}

const isSupport = () => {
  return getUserType() === 'support'
}

const isTeam = () => {
  return getUserType() === 'team'
}

const isAccountant = () => {
  return getUserType() === 'accountant'
}

const isMerchant = () => {
  return getUserType() === 'supplier'
}

const isSeller = () => {
  return getUserType() === 'seller'
}

const canEditMerchant = () => {
  return isAdmin() || isSupport()
}

const canEditShop = () => {
  return isAdmin() || isSupport()
}

const canEditUser = () => {
  return isAdmin() || isSupport()
}

const canEditTransactions = () => {
  return isAdmin()
}

const canViewShop = () => {
  return isAdmin() || isSupport() || isTeam() || isAccountant() || isMerchant() || isMerchant()
}

const canViewMerchant = () => {
  return isAdmin() || isSupport() || isTeam()
}

const canViewTransactions = () => {
  return isAdmin() || isSupport() || isTeam() || isMerchant() || isAccountant()
}

const canViewUser = () => {
  return isAdmin() || isSupport() || isTeam() || isMerchant()
}

const hasReadRights = () => {
  return isAdmin() || isSupport() || isTeam()
}

export {
  appUser,
  getRoles,
  isAdmin,
  isSupport,
  isTeam,
  isAccountant,
  isMerchant,
  isSeller,
  canEditMerchant,
  canEditShop,
  canEditUser,
  canEditTransactions,
  canViewShop,
  canViewMerchant,
  canViewTransactions,
  canViewUser,
  hasReadRights,
}
