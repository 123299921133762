interface IReducer {
  users: any[]
  user: any
  searchedUsersList: any[]
  isFetching: boolean
  loader: boolean
  isSearchFetching: boolean
  error: any
  filters: any[]
  localisations: any[]
  settings: any
  settingsLoading: boolean
  list: any[]
}

const initialState: IReducer = {
  users: [],
  user: null,
  searchedUsersList: [],
  isFetching: false,
  loader: false,
  isSearchFetching: false,
  error: null,
  filters: [],
  localisations: [],
  settings: {},
  settingsLoading: false,
  list: [],
}

const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SEARCH_FETCHING': {
      return {
        ...state,
        isSearchFetching: true,
      }
    }
    case 'FETCHING': {
      return {
        ...state,
        users: [],
        isFetching: true,
      }
    }

    case 'RECEIVE_USERS': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'FETCH_USER_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'FETCHING_USER': {
      return {
        ...state,
        user: null,
        isFetching: true,
      }
    }

    case 'RECEIVE_USER': {
      return {
        ...state,
        user: action.payload,
        isFetching: false,
      }
    }

    case 'CLEAN_USER': {
      return {
        ...state,
        user: null,
        isFetching: false,
      }
    }

    case 'TOGGLE_USER_REQUESTED': {
      return {
        ...state,
      }
    }
    case 'TOGGLE_USER_SUCCESS': {
      return {
        ...state,
        list: state.list.map((user) =>
          user.id === action.payload ? { ...user, is_deleted: !user.is_deleted } : user
        ),
      }
    }
    case 'TOGGLE_USER_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }

    case 'RECEIVE_SEARCH_USERS': {
      return {
        ...state,
        isSearchFetching: false,
        searchedTotal: action.payload.total,
        searchedUsersList: action.payload.data,
      }
    }

    case 'FETCH_USERS_ERROR': {
      return {
        ...state,
        isSearchFetching: false,
        error: action.payload,
      }
    }
    case 'DISPLAY_USERS_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case 'RESET_USERS_FILTERS': {
      return {
        ...state,
        filters: [],
      }
    }
    case 'GET_LOCALISATION_NAME': {
      return {
        ...state,
        localisations: action.payload,
      }
    }
    case 'UPDATING_USER_WALLET': {
      return {
        ...state,
      }
    }
    case 'UPDATED_USER_WALLET': {
      return {
        ...state,
        user: {
          ...state.user,
          wallets: state.user.wallets.map((wallet: any) =>
            wallet.id === action.payload.id ? { ...wallet, ...action.payload } : wallet
          ),
        },
      }
    }
    case 'UPDATED_USER_WALLET_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'FETCHING_USER_SETTINGS': {
      return {
        ...state,
        settingsLoading: true,
      }
    }
    case 'RECEIVE_USER_SETTINGS': {
      return {
        ...state,
        settings: action.payload,
        settingsLoading: false,
      }
    }
    case 'FETCH_USER_SETTINGS_ERR': {
      return {
        ...state,
        settingsLoading: false,
        error: action.payload,
      }
    }
    case 'UPDATING_USER_SETTINGS': {
      return {
        ...state,
      }
    }
    case 'UPDATED_USER_SETTINGS': {
      return {
        ...state,
      }
    }
    case 'UPDATING_USER_SETTINGS_ERR': {
      return {
        ...state,
      }
    }
    case 'ADDING_USER_LOCALISATION': {
      return {
        ...state,
      }
    }
    case 'ADDED_USER_LOCALISATION': {
      return {
        ...state,
        user: {
          ...state.user,
          localisation: [...state.user.localisation, action.payload],
        },
      }
    }
    case 'ADDING_USER_LOCALISATION_ERR': {
      return {
        ...state,
      }
    }
    case 'DELETING_USER_LOCALISATION': {
      return {
        ...state,
      }
    }
    case 'DELETED_USER_LOCALISATION': {
      return {
        ...state,
        user: {
          ...state.user,
          localisation: state.user.localisation.filter(
            (localisation: any) => localisation.id !== action.payload
          ),
        },
      }
    }
    case 'DELETING_USER_LOCALISATION_ERR': {
      return {
        ...state,
      }
    }
    case 'CREATING_USER': {
      return {
        ...state,
      }
    }
    case 'CREATED_USER': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'CREATING_USER_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'UPDATING_USER': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'UPDATED_USER': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        isFetching: false,
      }
    }
    case 'UPDATING_USER_ERR': {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    }
    case 'DELETING_USER': {
      return {
        ...state,
      }
    }
    case 'DELETED_USER': {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'DELETING_USER_ERR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default usersReducer
