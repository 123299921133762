interface IReducer {
  list: any[]
  total: number
  error: any
  isFetching: boolean
  filters: any
}

const initialState: IReducer = {
  list: [],
  isFetching: false,
  total: 0,
  error: null,
  filters: null,
}

const shopsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
        list: [],
        total: 0,
      }
    }
    case 'RECEIVE_SHOPS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }
    case 'FETCH_SHOPS_ERROR': {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    }
    case 'RESET_SHOPS_FILTERS': {
      return {
        ...state,
        filters: null,
      }
    }

    case 'SET_SHOPS_FILTERS': {
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }
    }

    case 'REPLACE_SHOPS_FILTERS': {
      return {
        ...state,
        filters: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default shopsReducer
