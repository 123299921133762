import { Popover } from 'antd'
import React from 'react'
import dayjs from 'dayjs'
import { datadogLogs } from '@datadog/browser-logs'
import Store from 'store'
import { formatMessage } from './currentLocale'
import Config from '../Config'
import * as queryString from 'querystring'
import { trim } from 'lodash'
import * as gtag from '../utils/gtag'
import { formatPrice } from '@leshabitues/leshabitues-ui'

export function checkLocalisations(localisations: any[]) {
  if (!localisations) {
    return null
  }

  if (localisations.length === 1) {
    return (
      <span className="shop-card__details__address">
        {localisations[0].address},{localisations[0].zipcode} {localisations[0].city}
      </span>
    )
  }
  return (
    <span className="shop-card__details__address">
      <i className="lh lh-Location" />
      <Popover
        content={localisations.map((loc) => (
          <span className="tool-overlay__item merchants" key={loc.id}>
            {loc.address},{loc.zipcode} {loc.city}
          </span>
        ))}
        trigger="hover"
        placement="bottom"
      >
        {localisations.length} {formatMessage({ id: 'shops_list.table.rows.localisations' })}
      </Popover>
    </span>
  )
}

export function sendGAModalView(
  props: { visible: boolean; title?: string },
  prevProps: { visible: boolean },
  title?: string
) {
  if (props.visible !== prevProps.visible && props.visible && !prevProps.visible) {
    gtag.modalview('open', `Modal - ${props.title || title}`)
  }

  if (props.visible !== prevProps.visible && !props.visible && prevProps.visible) {
    gtag.modalview('close', `Modal - ${props.title || title}`)
  }
}

export function checkRefreshTokenStatus() {
  const paramsUrl = queryString.parse(trim(window.location.search, '?'))

  if (Object.prototype.hasOwnProperty.call(paramsUrl, 'stats')) {
    return
  }

  if (+Store.get('lh_refresh_token_exp') < dayjs().unix()) {
    const remember_login = Store.get('remember_login')
    Store.clearAll()

    if (remember_login) {
      Store.set('remember_login', remember_login)
    }

    window.location.href = '/login'
  }
}

export function checkSession() {
  const paramsUrl = queryString.parse(trim(window.location.search, '?'))

  if (Object.prototype.hasOwnProperty.call(paramsUrl, 'stats')) {
    return
  }

  if (Store.get('last_session')) {
    if (dayjs().unix() - Store.get('last_session') > Config.session_lifetime) {
      const remember_login = Store.get('remember_login')
      Store.clearAll()

      if (remember_login) {
        Store.set('remember_login', remember_login)
      }

      datadogLogs.addLoggerGlobalContext('user', null)
      window.location.href = '/login'
    }
  } else {
    Store.set('last_session', dayjs().unix())
  }
}

export function cleanUserLog() {
  const user = Store.get('lh_user')

  if (user) {
    delete user.email
    delete user.lastname
    delete user.firstname
  }

  return user
}

export function displayFiltersRange(range: [number, number], min: number, max: number) {
  if (range) {
    if (range[0] && range[1]) {
      return `${range[0]} - ${range[1]}`
    }

    if (range[0]) {
      return `${range[0]} - ${max}`
    }

    return `${min} - ${range[1]}`
  }
  return `${min} - ${max}`
}

export function renderPaymentIcon(type: string) {
  switch (type) {
    case 'titrerestaurant':
      return <i className="lh lh-Payment-Titre-Restaurant" />
    case 'cash':
      return <i className="lh lh-Cash" />
    case 'visamastercard':
    case 'mypos':
      return <i className="lh lh-Credit-Card" />
    case 'check':
      return <i className="lh lh-Payment-Check" />
    case 'giftcard':
    case 'cashback':
      return <i className="lh lh-Gift" />
    default:
      return <i className="lh lh-Money-Euros" />
  }
}

export function formatSupplyPrice(price: number) {
  const formattedPrice = formatPrice(price).split(',')
  return (
    <>
      <span>{formattedPrice[0]}</span>
      <span>{formattedPrice[1]}</span>
    </>
  )
}
