import React from 'react'
import SubMenu from './SubMenu'
import { ISubMenuLink } from './ISubMenuLink'
import { canViewTransactions, hasReadRights } from '../../utils/roles'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const SubMenuTransactions: React.FunctionComponent<WrappedComponentProps> = ({ intl }) => {
  const subMenuLinks: ISubMenuLink[] = [
    {
      name: intl.formatMessage({ id: 'transactions.navbar.transactions' }),
      url: '/transactions',
      isRoute: true,
      authorized: canViewTransactions(),
    },
    {
      name: intl.formatMessage({ id: 'transactions.navbar.invoices' }),
      url: '/invoices',
      isRoute: true,
      authorized: hasReadRights(),
    },
    {
      name: intl.formatMessage({ id: 'transactions.navbar.ecommerce' }),
      url: '/ecommerces',
      isRoute: true,
      authorized: hasReadRights(),
    },
    {
      name: intl.formatMessage({ id: 'transactions.navbar.remises' }),
      url: '/remises',
      isRoute: true,
      authorized: hasReadRights(),
    },
  ]

  return <SubMenu subMenuLinks={subMenuLinks} />
}

export default injectIntl(SubMenuTransactions)
