import { combineReducers } from 'redux'
import categoriesReducer from './categoriesReducer'
import equipmentsReducer from './equipmentsReducer'
import equipmentsTypesReducer from './equipmentTypesReducer'
import filtersReducer from './filtersReducer'
import invoicesReducer from './invoicesReducer'
import merchantsReducer from './merchantsReducer'
import productsReducer from './productsReducer'
import shipmentReducer from './shipmentReducer'
import shopsReducer from './shopsReducer'
import subscriptionReducer from './subscriptionReducer'
import suppliesReducer from './suppliesReducer'
import teamReducer from './teamReducer'
import transactionsReducer from './transactionsReducer'
import usersReducer from './usersReducer'
import shopReducer from './shopReducer'
import discountsReducer from './discountsReducer'
import remisesReducer from './remisesReducer'
import walletsReducer from './walletsReducer'
import newsReducer from './newsReducer'
import messagesReducer from './messagesReducer'
import toolsReducer from './toolsReducer'

const appReducer = combineReducers({
  categoriesReducer,
  equipmentsTypesReducer,
  equipmentsReducer,
  filtersReducer,
  invoicesReducer,
  merchantsReducer,
  productsReducer,
  shipmentReducer,
  shopsReducer,
  subscriptionReducer,
  suppliesReducer,
  teamReducer,
  transactionsReducer,
  usersReducer,
  shopReducer,
  discountsReducer,
  remisesReducer,
  walletsReducer,
  newsReducer,
  messagesReducer,
  toolsReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
