import Store from 'store'
import Config from '../Config'
import Routes from './routes.json'

const adminUrl = `${Config.legacy_url}/admin`
const websiteUrl = `${Config.legacy_url}`
const routes: IRoutes = Routes

interface IRoutes {
  [key: string]: string
}

interface IParams {
  [key: string]: string
}

export default class Routing {
  static generate(name: string, optParams: IParams = null): string {
    const url: string = routes[name]

    if (optParams) {
      const params = new URLSearchParams()

      Object.keys(optParams).forEach((key: string) => {
        if (optParams[key] !== null) {
          params.set(key, optParams[key])
        }
      })

      return `${url}?${params.toString()}`
    }

    return url
  }

  static generateLegacy(url: string): string {
    return `${adminUrl}/sso.php?r=${encodeURIComponent(url)}&t=${Store.get('lh_token')}`
  }

  static generateWebsite(route: string, params?: IParams): string {
    let url = routes[route]

    if (params) {
      Object.keys(params).forEach((key: string) => {
        if (params[key] !== null) {
          url = url.replace(`{${key}}`, params[key])
        }
      })
    }

    return `${websiteUrl}${url}`
  }
}
