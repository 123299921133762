import React from 'react'
import SubMenu from './SubMenu'
import { ISubMenuLink } from './ISubMenuLink'
import { canViewTransactions, hasReadRights } from '../../utils/roles'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const SubMenuTools: React.FunctionComponent<WrappedComponentProps> = ({ intl }) => {
  const subMenuLinks: ISubMenuLink[] = [
    {
      name: intl.formatMessage({ id: 'tools.navbar.feature_flags' }),
      url: '/tools/features',
      isRoute: true,
      authorized: hasReadRights(),
    },
    {
      name: intl.formatMessage({ id: 'tools.navbar.remise_converter' }),
      url: '/tools/remise-converter',
      isRoute: true,
      authorized: hasReadRights(),
    },
    {
      name: intl.formatMessage({ id: 'tools.navbar.products' }),
      url: '/tools/products',
      isRoute: true,
      authorized: hasReadRights(),
    },
    {
      name: intl.formatMessage({ id: 'tools.navbar.notifications' }),
      url: '/tools/notifications',
      isRoute: true,
      authorized: hasReadRights(),
    },
  ]

  return <SubMenu subMenuLinks={subMenuLinks} />
}

export default injectIntl(SubMenuTools)
