interface IReducer {
  isFetching: boolean
  wallet: any
}

const initialState: IReducer = {
  isFetching: false,
  wallet: null,
}

const walletsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_WALLET_TRANSACTIONS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_WALLET_TRANSACTIONS': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'FETCH_WALLET_TRANSACTIONS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default walletsReducer
