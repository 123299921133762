interface IReducer {
  total: number
  list: any[]
  isFetching: boolean
  error: any
}

const initialState: IReducer = {
  total: 0,
  list: [],
  isFetching: false,
  error: null,
}

const newsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        total: 0,
        list: [],
        isFetching: true,
      }
    }

    case 'RECEIVE_NEWS': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }

    case 'FETCH_NEWS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'RECEIVE_NEWS_SHOP': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }

    case 'FETCH_NEWS_SHOP_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'CREATING_NEWS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'CREATED_NEWS': {
      return {
        ...state,
        isFetching: false,
        list: [action.payload, ...state.list],
        total: state.total + 1,
      }
    }
    case 'CREATING_NEWS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'UPDATING_NEWS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'UPDATED_NEWS': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((news) =>
          news.id === action.payload.id ? { ...news, ...action.payload } : news
        ),
      }
    }
    case 'UPDATING_NEWS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'DELETING_NEWS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'DELETED_NEWS': {
      return {
        ...state,
        isFetching: false,
        list: state.list.filter((news) => news.id !== action.payload),
        total: state.total - 1,
      }
    }
    case 'DELETING_NEWS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default newsReducer
