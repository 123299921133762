interface IReducer {
  equipments: any[]
  equipment: any[]
  searchedEquipmentsList: any[]
  isFetching: boolean
  loader: boolean
  isSearchFetching: boolean
  error: any
  filters: any[]
}

const initialState: IReducer = {
  equipments: [],
  equipment: [],
  searchedEquipmentsList: [],
  isFetching: false,
  loader: false,
  isSearchFetching: false,
  error: null,
  filters: [],
}

const equipmentTypesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SEARCH_FETCHING': {
      return {
        ...state,
        isSearchFetching: true,
      }
    }
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'FETCH_EQUIPMENT_TYPES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'RECEIVE_EQUIPMENT_TYPE': {
      return {
        ...state,
        isFetching: false,
        equipmentType: [action.payload],
      }
    }
    case 'RECEIVE_EQUIPMENT_TYPES': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }
    default: {
      return state
    }
  }
}

export default equipmentTypesReducer
