interface IReducer {
  products: {
    list: any[]
    total: number
    isFetching: boolean
    hasMore: boolean
  }
  total: 0,
  product: any
  searchedProductsList: any[]
  page: number
  isSearchFetching: boolean
  error: any
  filters: any[]
  localisations: any[]
  settings: any
  settingsLoading: boolean
  list: any[]
}

const initialState: IReducer = {
  
  products: {
    isFetching: false,
    list: [],
    total: 0,
    hasMore: false,
  },
  total: 0,
  product: null,
  searchedProductsList: [],
  error: null,
  page: 1,
  isSearchFetching: false,
  filters: [],
  localisations: [],
  settings: {},
  settingsLoading: false,
  list: [],
}

const productsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        products: {
          isFetching: true,
          list: [],
          total: 0,
          hasMore: false,
        },
      }
    }

    case 'RECEIVE_PRODUCTS': {
      return {
        ...state,
        products: {
          isFetching: false,
          list: action.payload.data,
          total: action.payload.total,
          hasMore: action.payload.has_more,
        },
        isFetching: true,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'CREATING_PRODUCT': {
      return {
        ...state,
      }
    }

    case 'PRODUCTS_CREATE_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'PRODUCTS_CREATE_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'UPDATED_PRODUCTS': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((products) =>
          products.id === action.payload.id ? { ...products, ...action.payload } : products
        ),
      }
    }

    case 'UPDATING_PRODUCTS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'UPDATING_PRODUCTS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'FETCH_PRODUCTS_ERROR': {
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: false,
        },
        error: action.payload,
      }
    }

    case 'FETCHING_INVOICES_PRODUCTS': {
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: true,
        },
      }
    }

    case 'RECEIVE_INVOICES_PRODUCTS': {
      return {
        ...state,
        products: {
          isFetching: false,
          list: [...state.products.list, ...action.payload.data],
          total: action.payload.total,
          hasMore: action.payload.has_more,
        },
        page: state.page + 1,
      }
    }

    case 'FETCH_INVOICES_PRODUCTS_ERR': {
      return {
        ...state,
        products: {
          ...state.products,
          isFetching: false,
        },
        error: action.payload,
      }
    }

    case 'DELETING_PRODUCTS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'DELETED_PRODUCTS': {
      return {
        ...state,
        isFetching: false,
        list: state.list.filter((products) => products.id !== action.payload),
        total: state.total - 1,
      }
    }
    case 'DELETING_PRODUCTS_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'CLEAR_INVOICES_PRODUCTS': {
      return {
        products: {
          isFetching: false,
          list: [],
          total: 0,
          hasMore: false,
        },
        error: null,
        page: 1,
      }
    }
    default:
      return state
  }
}

export default productsReducer
