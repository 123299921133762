interface IReducer {
  error: any
  list: any[]
  total: number
  isFetching: boolean
}

const initialState: IReducer = {
  list: [],
  total: 0,
  isFetching: false,
  error: null,
}

const remisesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_REMISES': {
      return {
        ...state,
        isFetching: true,
        list: [],
        total: 0,
      }
    }
    case 'RECEIVE_REMISES': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }
    case 'FETCH_REMISES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'VALIDATE_REMISE': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'VALIDATED_REMISE': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((remise) =>
          remise.id === action.payload.id ? action.payload : remise
        ),
      }
    }
    case 'VALIDATED_REMISE_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default remisesReducer
