import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'

interface IProps {
  intl: any
}

const Unauthorized = ({ intl }: IProps) => {
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'page_title.unauthorized' })
  })

  return (
    <div className="lhd-not-found">
      <span>403</span>
      <h1>{intl.formatMessage({ id: 'global.error.403' })}</h1>
    </div>
  )
}
export default injectIntl(Unauthorized)
