import TeamUser from '../../types/TeamUser'

interface IReducer {
  team: TeamUser[]
  error: any
}

const initialState: IReducer = {
  team: [],
  error: null,
}

const teamReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCH_TEAM': {
      return {
        ...state,
        team: action.payload,
      }
    }
    case 'FETCH_TEAM_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default teamReducer
