const Config = {
  url: process.env.REACT_APP_URL,
  algolia_appID: process.env.REACT_APP_ALGOLIA_APP_ID,
  algolia_searchKey: process.env.REACT_APP_ALGOLIA_SEARCH_KEY,
  algolia_indexName: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}-localisations`,
  algolia_shop_indexName: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}-shops`,
  x_app_id: process.env.REACT_APP_X_APP_ID,
  cdn_s3: process.env.REACT_APP_CDN_S3,
  legacy_url: process.env.REACT_APP_LEGACY_URL,
  google_api_key: process.env.REACT_APP_GOOGLE_API_KEY,
  dashboard_url: process.env.REACT_APP_DASHBOARD_URL,
  slimpay_dashboard_url: process.env.REACT_APP_SLIMPAY_DASHBOARD_URL,
  google_analytics_id: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  datadog_key: process.env.REACT_APP_DATADOG_KEY,
  refresh_token_lifetime: +process.env.REACT_APP_REFRESH_TOKEN_LIFETIME,
  session_lifetime: +process.env.REACT_APP_SESSION_LIFETIME,
  stats_url: process.env.REACT_APP_STATS_URL,
  reCaptchaSite: process.env.REACT_APP_RECAPTCHA_SITE,
}

export default Config
