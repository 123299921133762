interface IReducer {
  isFetching: boolean
  chatRooms: any[]
  chatRoomsTotal: number
  isFetchingThreads: boolean
  chatroomThreads: any[]
}

const initialState: IReducer = {
  isFetching: false,
  chatRooms: [],
  chatRoomsTotal: 0,
  isFetchingThreads: false,
  chatroomThreads: [],
}

const messagesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_CHAT_ROOMS': {
      return {
        ...state,
        chatRooms: [],
        chatRoomsTotal: 0,
        isFetching: true,
        chatroomThreads: [],
      }
    }

    case 'RECEIVE_CHAT_ROOMS': {
      return {
        ...state,
        isFetching: false,
        chatRooms: action.payload.data,
        chatRoomsTotal: action.payload.total,
      }
    }

    case 'FETCHING_CHAT_ROOMS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'FETCHING_CHATROOM_THREADS': {
      return {
        ...state,
        chatroomThreads: [],
        isFetchingThreads: true,
      }
    }

    case 'RECEIVE_CHATROOM_THREADS': {
      return {
        ...state,
        isFetchingThreads: false,
        chatroomThreads: action.payload.data,
      }
    }

    case 'FETCHING_CHATROOM_THREADS_ERROR': {
      return {
        ...state,
        isFetchingThreads: false,
        error: action.payload,
      }
    }

    case 'CLEAR_THREADS': {
      return {
        ...state,
        chatroomThreads: [],
      }
    }

    default:
      return state
  }
}

export default messagesReducer
