import omit from 'lodash/omit'

export default function reducer(
  state = {
    isOpen: false,
    category_id: 0,
    filter: {},
    toReset: {},
  },
  action: any
) {
  switch (action.type) {
    case 'TOGGLE_FILTERS': {
      return { ...state, isOpen: !state.isOpen }
    }
    case 'HANDLE_CATEGORY_CHANGE': {
      return { ...state, category_id: action.payload }
    }
    case 'ADD_FILTER_TOOL': {
      return {
        ...state,
        toReset: undefined,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      }
    }
    case 'RESET_FILTER': {
      return {
        ...state,
        toReset: action.payload,
        filter: omit(state.filter, action.payload),
      }
    }
    default: {
      return state
    }
  }
}
