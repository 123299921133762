import React, { useEffect, useRef, useState } from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Store from 'store'
import NavbarLink from './NavbarLink'
import {
  appUser,
  canViewMerchant,
  canViewShop,
  canViewTransactions,
  canViewUser,
  hasReadRights,
} from '../../utils/roles'
import LHLogo from '../../assets/img/bee.png'
import Config from '../../Config'
import cx from 'classnames'

type IProps = WrappedComponentProps

const Navbar: React.FunctionComponent<IProps> = (props: IProps) => {
  const [displayMenu, setDisplayMenu] = useState<boolean>(false)
  const node = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if ((e.target as Element).className === 'mobile-menu__menu-toggler') {
      return
    }

    setDisplayMenu(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  return (
    <div className="navbar" id="navbar">
      <div className="mobile-menu">
        {canViewShop() && appUser().shops.length === 1 ? (
          <div className="navbar__list-item logo">
            <NavbarLink
              to="/dashboard"
              isActive={(match, location) => location.pathname.startsWith('/dashboard')}
            >
              <img className="navbar__icon" src={LHLogo} alt="" />
            </NavbarLink>
          </div>
        ) : (
          <div className="navbar__list-item logo">
            <NavbarLink
              to="/shops"
              isActive={(match, location) => location.pathname.startsWith('/dashboard')}
            >
              <img className="navbar__icon" src={LHLogo} alt="" />
            </NavbarLink>
          </div>
        )}
        <div>
          <button
            className="mobile-menu__menu-toggler"
            role="button"
            onClick={() => setDisplayMenu(!displayMenu)}
          >
            <i className="lh lh-Burger-Menu"></i>
          </button>
        </div>
      </div>
      <div className={cx('navbar__wrapper', { open: displayMenu })} ref={node}>
        <ul className="navbar__list top-list">
          {canViewShop() && appUser().shops.length === 1 ? (
            <li className="navbar__list-item">
              <NavbarLink
                to={`/shop/account/${appUser().shop_id}`}
                isActive={(match, location) => location.pathname.startsWith('/shop')}
              >
                <i className="lh lh-Store" />
                <span>{props.intl.formatMessage({ id: 'navbar.shop' })}</span>
              </NavbarLink>
            </li>
          ) : (
            <li className="navbar__list-item">
              <NavbarLink
                to="/shops"
                isActive={(match, location) => location.pathname.startsWith('/shop')}
              >
                <i className="lh lh-Store" />
                <span>{props.intl.formatMessage({ id: 'navbar.shops' })}</span>
              </NavbarLink>
            </li>
          )}
          {canViewMerchant() && (
            <li className="navbar__list-item">
              <NavbarLink
                to="/merchants"
                isActive={(match, location) => location.pathname.startsWith('/merchant')}
              >
                <i className="lh lh-Account-Details" />
                <span>{props.intl.formatMessage({ id: 'navbar.merchants' })}</span>
              </NavbarLink>
            </li>
          )}
          {canViewUser() && (
            <li className="navbar__list-item">
              <NavbarLink
                to="/users"
                isActive={(match, location) => location.pathname.startsWith('/user')}
              >
                <i className="lh lh-Account-Circle" />
                <span>{props.intl.formatMessage({ id: 'navbar.customers' })}</span>
              </NavbarLink>
            </li>
          )}
          {canViewTransactions() && (
            <li className="navbar__list-item">
              <NavbarLink
                to="/transactions"
                isActive={(match, location) =>
                  location.pathname.startsWith('/transactions') ||
                  location.pathname.startsWith('/invoices') ||
                  location.pathname.startsWith('/ecommerces') ||
                  location.pathname.startsWith('/remises')
                }
              >
                <i className="lh lh-Transaction" />
                <span>{props.intl.formatMessage({ id: 'navbar.transactions' })}</span>
              </NavbarLink>
            </li>
          )}
          {hasReadRights() && (
            <li className="navbar__list-item">
              <NavbarLink to="/news">
                <i className="lh lh-Newspaper" />
                <span>{props.intl.formatMessage({ id: 'navbar.news' })}</span>
              </NavbarLink>
            </li>
          )}
          {hasReadRights() && (
            <li className="navbar__list-item">
              <NavbarLink to="/messaging">
                <i className="lh lh-Chat" />
                <span>{props.intl.formatMessage({ id: 'navbar.messages' })}</span>
              </NavbarLink>
            </li>
          )}
        </ul>
        <ul className="navbar__list bottom-list">
          {hasReadRights() && (
            <li className="navbar__list-item">
              <NavbarLink to="/tools/features">
                <i className="lh lh-Bug-Report" />
                <span>{props.intl.formatMessage({ id: 'navbar.tools' })}</span>
              </NavbarLink>
            </li>
          )}
          {hasReadRights() && (
            <li className="navbar__list-item">
              <a href={Config.stats_url} className="navbar__list-anchor">
                <i className="lh lh-Growth" />
                <span>{props.intl.formatMessage({ id: 'navbar.stats' })}</span>
              </a>
            </li>
          )}
          {hasReadRights() && (
            <li className="navbar__list-item">
              <NavbarLink to="/equipments">
                <i className="lh lh-Stock" />
                <span>{props.intl.formatMessage({ id: 'navbar.stock' })}</span>
              </NavbarLink>
            </li>
          )}
          <li className="navbar__list-item">
            <div className="navbar__list-anchor dropdown">
              <i className="lh lh-Account-Circle-Filled" />
              <i className="lh lh-Arrow-Chevron-Down" />
              <div className="dropdown-content">
                <ul>
                  <li>
                    <NavbarLink to={`/settings/account/${Store.get('lh_user').id}`}>
                      <i className="lh lh-Account-Circle" />
                      <span>{props.intl.formatMessage({ id: 'navbar.dropdown.account' })}</span>
                    </NavbarLink>
                  </li>
                  <li>
                    <NavbarLink to={`/settings/reports/${Store.get('lh_user').id}`}>
                      <i className="lh lh-Document" />
                      <span>{props.intl.formatMessage({ id: 'navbar.dropdown.reports' })}</span>
                    </NavbarLink>
                  </li>
                  <li>
                    <NavbarLink to="/logout">
                      <i className="lh lh-Log-Out" />
                      <span>{props.intl.formatMessage({ id: 'navbar.dropdown.logout' })}</span>
                    </NavbarLink>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default injectIntl(Navbar)
