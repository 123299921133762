import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Store from 'store'
import Navbar from './Navbar'
import { Base64 } from 'js-base64'
import queryString from 'querystring'
import { trim } from 'lodash'
import { withRouter } from 'react-router'
import * as gtag from '../../utils/gtag'

interface IProps extends RouteComponentProps {}

const Layout: React.FunctionComponent<IProps> = (props) => {
  useEffect(() => {
    const paramsUrl = queryString.parse(trim(window.location.search, '?'))

    if (!Store.get('lh_user') && !Object.prototype.hasOwnProperty.call(paramsUrl, 'stats')) {
      const url = window.location

      props.history.push(`/login?redirect=${Base64.encode(url.pathname + url.search)}`)
    }
  }, [])

  if (!Store.get('lh_user')) {
    return null
  }

  gtag.setUser(Store.get('lh_user').id)

  return (
    <div className="layout">
      <Navbar />
      {props.children}
    </div>
  )
}

export default withRouter(Layout)
