import React from 'react'
import { Redirect } from 'react-router'
import SubMenu from './SubMenu'
import { ISubMenuLink } from './ISubMenuLink'
import { canViewMerchant } from '../../utils/roles'

const SubMenuMerchant: React.FunctionComponent = () => {
  let merchantId = 0

  if (window.location.href.match(/\/merchant\/.*\/(\d+)/)) {
    merchantId = +window.location.href.match(/\/merchant\/.*\/(\d+)/)[1]
  } else {
    return <Redirect to="/merchants" />
  }

  const subMenuLinks: ISubMenuLink[] = [
    {
      name: 'Compte',
      url: `/merchant/account/${merchantId}`,
      isRoute: true,
      authorized: canViewMerchant(),
    },
    {
      name: 'Rapports',
      url: `/merchant/reports/${merchantId}`,
      isRoute: true,
      authorized: canViewMerchant(),
    },
    {
      name: 'Paramètres',
      url: `/merchant/settings/${merchantId}`,
      isRoute: true,
      authorized: canViewMerchant(),
    },
  ]

  return <SubMenu subMenuLinks={subMenuLinks} />
}

export default SubMenuMerchant
