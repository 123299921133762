import React from 'react'

export function queryStringToObject(queryString: any) {
  const queryObj = {}
  if (queryString) {
    queryString
      .slice(1)
      .split('&')
      .map((item) => {
        const pair = item.split('=')
        if (pair[1]) {
          queryObj[pair[0]] = pair[1]
        }
        return pair
      })
  }
  return queryObj
}

export function isDigit(str: string) {
  return /^\d+$/.test(str)
}

export function paginationRender(current, type, originalElement) {
  if (type === 'prev') {
    return <i className="lh lh-Arrow-Chevron-Left" />
  }
  if (type === 'next') {
    return <i className="lh lh-Arrow-Chevron-Right" />
  }
  return originalElement
}

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}
