import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Routing from '../../routing/Routing'
import { ISubMenuLink } from './ISubMenuLink'
import cx from 'classnames'

interface IProps {
  subMenuLinks: ISubMenuLink[]
}

const SubMenu: React.FunctionComponent<IProps> = (props: IProps) => {
  const { subMenuLinks } = props

  const [displaymenu, setDisplayMenu] = useState<boolean>(false)

  const allowedSubMenu = subMenuLinks.filter((link: ISubMenuLink) => link.authorized)

  const node = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if ((e.target as Element).className === 'submenu__menu-toggler') {
      return
    }

    setDisplayMenu(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  if (allowedSubMenu.length < 1) {
    return null
  }

  return (
    <div className="submenu" ref={node}>
      <button
        className="submenu__menu-toggler"
        role="button"
        onClick={() => setDisplayMenu(!displaymenu)}
      >
        <i className={cx('lh lh-Arrow-Chevron-Down', { open: displaymenu })}></i>
      </button>
      <ul className={cx('submenu__list', { show: displaymenu })}>
        {subMenuLinks
          .filter((link: ISubMenuLink) => link.authorized)
          .map((link: ISubMenuLink, i: number) => {
            if (link.isRoute) {
              return (
                <li key={i}>
                  <NavLink className="submenu__list-item" activeClassName="selected" to={link.url}>
                    {link.name}
                  </NavLink>
                </li>
              )
            }
            return (
              <li key={i}>
                <a href={Routing.generateLegacy(link.url)} className="submenu__list-item">
                  {link.name}
                </a>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default SubMenu
