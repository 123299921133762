import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Layout from '../Layout/Layout'

const PublicRoute = ({
  component: Component,
  title,
  noLayout,
  exact,
  path,
  intl,
  ...rest
}: any) => {
  useEffect(() => {
    if (title) {
      document.title = intl.formatMessage({ id: title })
    }
  })

  if (noLayout) {
    return <Route path={path} render={(props) => <Component match={props.match} />} />
  }

  return <Layout {...rest} component={Component} />
}

export default injectIntl(PublicRoute)
