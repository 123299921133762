import React from 'react'
import { injectIntl } from 'react-intl'

class CurrentLocale extends React.Component<{ intl: any }> {
  static instance: CurrentLocale = null

  componentDidMount() {
    if (!CurrentLocale.instance) {
      CurrentLocale.instance = this
    }
  }

  render() {
    return false
  }
}

export default injectIntl(CurrentLocale)

export function intl() {
  return CurrentLocale.instance.props.intl
}

export function formatMessage(identifier: { id: string }, values?: any) {
  return intl().formatMessage(identifier, values)
}
