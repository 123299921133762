interface IReducer {
  categories: any[]
  isFetching: boolean
  error: any
}

const initialState: IReducer = {
  categories: [],
  isFetching: false,
  error: null,
}

const categoriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_CATEGORIES': {
      return {
        ...state,
        isFetching: false,
        categories: action.payload.data,
      }
    }

    case 'FETCH_CATEGORIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default categoriesReducer
