interface IReducer {
  list: any[]
  isFetching: boolean
  error: any
  total: number
}

const initialState: IReducer = {
  list: [],
  isFetching: false,
  error: null,
  total: 0,
}

const invoicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
        list: [],
        total: 0,
      }
    }

    case 'RECEIVE_INVOICES': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'FETCH_INVOICES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'FETCHING_INVOICES_SHOP': {
      return {
        ...state,
        list: [],
        total: 0,
        isFetching: true,
      }
    }
    case 'RECEIVE_INVOICES_SHOP': {
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        total: action.payload.total,
      }
    }
    case 'FETCH_INVOICES_SHOP_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'CREATING_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'CREATED_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: false,
        list: [action.payload, ...state.list],
        total: state.total + 1,
      }
    }
    case 'CREATING_INVOICE_SHOP_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'UPDATING_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'UPDATED_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((invoice) =>
          invoice.id === action.payload.id ? action.payload : invoice
        ),
      }
    }
    case 'UPDATING_INVOICE_SHOP_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    case 'DELETING_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'DELETED_INVOICE_SHOP': {
      return {
        ...state,
        isFetching: false,
        list: state.list.filter((invoice) => invoice.id !== action.payload),
        total: state.total - 1,
      }
    }
    case 'DELETED_INVOICE_SHOP_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'ADDING_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'ADDED_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((invoice) =>
          invoice.id === action.payload.id ? action.payload : invoice
        ),
      }
    }
    case 'ADDING_INVOICE_PRODUCT_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    case 'DELETING_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'DELETED_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((invoice) =>
          invoice.id === action.payload.id ? action.payload : invoice
        ),
      }
    }
    case 'DELETING_INVOICE_PRODUCT_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    case 'UPDATING_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'UPDATED_INVOICE_PRODUCT': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((invoice) =>
          invoice.id === action.payload.id ? action.payload : invoice
        ),
      }
    }
    case 'UPDATING_INVOICE_PRODUCT_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    default:
      return state
  }
}

export default invoicesReducer
