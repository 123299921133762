import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import Store from 'store'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import Unauthorized from './Unauthorized'

interface IPrivateRoute extends WrappedComponentProps {
  component: any
  path?: string
  title: string
  SubMenu?: any
  displaySubMenu?: boolean
  allowed?: boolean
}

const PrivateRoute = ({
  component: Component,
  path,
  title,
  SubMenu,
  displaySubMenu = true,
  allowed = true,
  intl,
}: IPrivateRoute) => {
  if (!Store.get('lh_user')) {
    return <Redirect to="/login" />
  }

  if (!allowed) {
    return <Unauthorized />
  }

  useEffect(() => {
    document.title = intl.formatMessage({ id: title })
  })

  return (
    <Route
      path={path}
      render={(props) => (
        <>
          {SubMenu && displaySubMenu && <SubMenu />}
          <div className="lhd-page">
            <Component match={props.match} />
          </div>
        </>
      )}
    />
  )
}

export default injectIntl(PrivateRoute)
