interface IReducer {
  discounts: any[]
  isFetching: boolean
  error: any
}

const initialState: IReducer = {
  discounts: [],
  isFetching: false,
  error: null,
}

const discountsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_DISCOUNTS': {
      return {
        ...state,
        isFetching: false,
        discounts: action.payload.data,
      }
    }

    case 'FETCH_DISCOUNTS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default discountsReducer
