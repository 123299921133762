import { Route, Switch, useLocation } from 'react-router-dom'
import React from 'react'
import { Redirect } from 'react-router'
import PublicRoute from './Routes/PublicRoute'
import Layout from './Layout/Layout'
import PrivateRoute from './Routes/PrivateRoute'
import * as gtag from '../utils/gtag'
import {
  canEditMerchant,
  canEditUser,
  canViewMerchant,
  canViewShop,
  canViewTransactions,
  canViewUser,
  hasReadRights,
} from '../utils/roles'
// submenu
import SubMenuUser from './Layout/SubMenuUser'
import SubMenuMerchant from './Layout/SubMenuMerchant'
import SubMenuShop from './Layout/SubMenuShop'
import SubMenuTransactions from './Layout/SubMenuTransactions'
import SubMenuSettings from './Layout/SubMenuSettings'
import SubMenuTools from './Layout/SubMenuTools'

// public
const Login = React.lazy(() => import('./Login/Login'))
const ResetPassword = React.lazy(() => import('./ResetPassword'))
const Logout = React.lazy(() => import('./Logout/Logout'))
const Notfound = React.lazy(() => import('./Routes/NotFound'))
const Unauthorized = React.lazy(() => import('./Routes/Unauthorized'))

// private
const ShopsList = React.lazy(() => import('./Shops/Shops'))
const Users = React.lazy(() => import('./Users/Users'))
const UserRouter = React.lazy(() => import('./User/UserRouter'))
const UserAccount = React.lazy(() => import('./User/Account/UserAccount'))
const Invoices = React.lazy(() => import('./invoices'))
const Transactions = React.lazy(() => import('./Transactions/Transactions'))
const Ecommerces = React.lazy(() => import('./ecommerces'))
const Equipments = React.lazy(() => import('./equipments/index'))
const ShopRouter = React.lazy(() => import('./Shop/ShopRouter'))
const MerchantRouter = React.lazy(() => import('./Merchants/MerchantRouter'))
const MerchantsList = React.lazy(() => import('./Merchants/List/MerchantsList'))
const Remises = React.lazy(() => import('./Remises/Remises'))
const MerchantAccount = React.lazy(() => import('./Merchants/Account/MerchantAccount'))
const News = React.lazy(() => import('./News/News'))
const SettingsRouter = React.lazy(() => import('./Routes/SettingsRouter'))
const Dashboard = React.lazy(() => import('./Dashboard'))
const MessagingRouter = React.lazy(() => import('./Messaging/MessagingRouter'))
const OnboardingRouter = React.lazy(() => import('./Onboarding/OnboardingRouter'))
const ToolsRouter = React.lazy(() => import('./Tools/ToolsRouter'))

const App = (props) => {
  const location = useLocation()
  React.useEffect(() => {
    gtag.pageview(location.pathname, document.title)
  }, [location])

  return (
    <Switch>
      <PublicRoute exact path="/login" component={Login} noLayout title="page_title.login" />
      <PublicRoute exact path="/logout" component={Logout} noLayout title="page_title.logout" />
      <PublicRoute
        exact
        path="/reset-password"
        component={ResetPassword}
        noLayout
        title="page_title.reset_password"
      />
      <PublicRoute path="/onboarding" component={OnboardingRouter} noLayout />
      <Route exact path="/" render={() => <Redirect to="/login" />} />
      <Layout>
        <Switch>
          <PrivateRoute
            path="/dashboard/:id?"
            component={Dashboard}
            title="page_title.dashboard"
            allowed={canViewShop()}
          />
          <PrivateRoute
            path="/shops"
            component={ShopsList}
            title="page_title.shops"
            allowed={canViewShop()}
          />
          <PrivateRoute
            path="/users"
            component={Users}
            title="page_title.users"
            allowed={canViewUser()}
          />
          <PrivateRoute
            path="/user/create"
            component={UserAccount}
            title="page_title.user.create"
            allowed={canEditUser()}
          />
          <PrivateRoute
            path="/user"
            component={UserRouter}
            SubMenu={SubMenuUser}
            title="page_title.user.label"
            allowed={canViewUser()}
          />
          <PrivateRoute
            path="/equipments"
            component={Equipments}
            title="page_title.equipments"
            allowed={hasReadRights()}
          />
          <PrivateRoute
            path="/transactions"
            component={Transactions}
            SubMenu={SubMenuTransactions}
            displaySubMenu
            title="page_title.transactions"
            allowed={canViewTransactions()}
          />
          <PrivateRoute
            path="/invoices"
            component={Invoices}
            SubMenu={SubMenuTransactions}
            displaySubMenu
            title="page_title.invoices"
            allowed={canViewTransactions()}
          />
          <PrivateRoute
            path="/ecommerces"
            component={Ecommerces}
            SubMenu={SubMenuTransactions}
            displaySubMenu
            title="page_title.ecommerces"
            allowed={canViewTransactions()}
          />
          <PrivateRoute
            path="/shop"
            component={ShopRouter}
            SubMenu={SubMenuShop}
            title="page_title.shop.label"
            allowed={canViewShop()}
          />
          <PrivateRoute
            path="/merchants"
            component={MerchantsList}
            title="page_title.merchants"
            allowed={canViewMerchant()}
          />
          <PrivateRoute
            path="/merchant/create"
            component={MerchantAccount}
            title="page_title.merchant.create"
            allowed={canEditMerchant()}
          />
          <PrivateRoute
            path="/merchant"
            component={MerchantRouter}
            SubMenu={SubMenuMerchant}
            title="page_title.merchant.label"
            allowed={canViewMerchant()}
          />
          <PrivateRoute
            path="/messaging"
            component={MessagingRouter}
            SubMenu={null}
            title="page_title.messaging.label"
            allowed={hasReadRights()}
          />
          <PrivateRoute
            path="/settings"
            component={SettingsRouter}
            SubMenu={SubMenuSettings}
            title="page_title.settings.label"
          />
          <PrivateRoute
            path="/remises"
            component={Remises}
            SubMenu={SubMenuTransactions}
            displaySubMenu
            title="page_title.remises"
            allowed={hasReadRights()}
          />
          <PrivateRoute
            path="/news"
            component={News}
            SubMenu={null}
            displaySubMenu={false}
            title="page_title.shop.news"
            allowed={hasReadRights()}
          />
          <PrivateRoute
            path="/tools"
            component={ToolsRouter}
            SubMenu={SubMenuTools}
            displaySubMenu={true}
            title="page_title.tools.label"
            allowed={hasReadRights()}
          />
          <PrivateRoute path="/not-found" component={Notfound} title="page_title.not_found" />
          <PrivateRoute
            path="/unauthorized"
            component={Unauthorized}
            title="page_title.unauthorized"
          />
          <PrivateRoute component={Notfound} title="page_title.not_found" />
        </Switch>
      </Layout>
    </Switch>
  )
}

export default App
