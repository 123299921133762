interface IReducer {
  shop: any
  isFetching: boolean
  shopLocalisations: any[]
  shopGifts: any[]
  localisationLoading: boolean
  giftsLoading: boolean
  incentivePrograms: {
    total: number
    list: any[]
    isFetching: boolean
  }
  offers: {
    total: number
    list: any[]
    isFetching: boolean
  }
  incentiveInvoices: any
  incentiveInvoicesLoading: boolean
  subscriptionFetching: boolean
  subscription: any
  error: any
  parameters: any[]
  parametersLoading: boolean
  reportingSchedules: {
    data: any[]
  }
  reportingSchedulesLoading: boolean
  stats: any
  isFetchingStats: boolean
  localisation: any
  localisations: {
    total: number
    list: any[]
    isFetching: boolean
  }
  users: {
    total: number
    list: any[]
    isFetching: boolean
  }
}

const initialState: IReducer = {
  shop: {},
  isFetching: false,
  shopLocalisations: [],
  shopGifts: [],
  localisationLoading: false,
  giftsLoading: false,
  incentivePrograms: {
    total: 0,
    list: [],
    isFetching: false,
  },
  offers: {
    total: 0,
    list: [],
    isFetching: false,
  },
  incentiveInvoices: {},
  incentiveInvoicesLoading: false,
  subscriptionFetching: false,
  subscription: {},
  error: null,
  parameters: [],
  parametersLoading: false,
  reportingSchedules: {
    data: [],
  },
  reportingSchedulesLoading: false,
  stats: {},
  isFetchingStats: false,
  localisation: {},
  localisations: {
    total: 0,
    list: [],
    isFetching: false,
  },
  users: {
    total: 0,
    list: [],
    isFetching: false,
  },
}

const shopReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_SHOP': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'FETCH_SHOP_ERROR': {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    }
    case 'RECEIVE_SHOP': {
      return {
        ...state,
        isFetching: false,
        shop: action.payload,
      }
    }

    case 'FETCHING_SHOP_LOCALISATIONS': {
      return {
        ...state,
        localisationLoading: true,
        shopLocalisations: [],
      }
    }
    case 'FETCH_SHOP_LOCALISATIONS_ERROR': {
      return {
        ...state,
        error: action.payload,
        localisationLoading: false,
      }
    }
    case 'RECEIVE_SHOP_LOCALISATIONS': {
      return {
        ...state,
        localisationLoading: false,
        shopLocalisations: action.payload,
      }
    }
    case 'UPDATING_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: true,
      }
    }

    case 'UPDATED_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: false,
        shopLocalisations: state.shopLocalisations.map((localisation) =>
          localisation.id === action.payload.id ? action.payload : localisation
        ),
      }
    }
    case 'UPDATING_SHOP_LOCALISATION_ERROR': {
      return {
        ...state,
        localisationLoading: false,
      }
    }
    case 'CREATING_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: true,
      }
    }
    case 'CREATED_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: false,
        shopLocalisations: [action.payload, ...state.shopLocalisations],
      }
    }
    case 'CREATING_SHOP_LOCALISATION_ERROR': {
      return {
        ...state,
        localisationLoading: false,
        error: action.payload,
      }
    }
    case 'DELETING_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: true,
      }
    }
    case 'DELETED_SHOP_LOCALISATION': {
      return {
        ...state,
        localisationLoading: false,
        shopLocalisations: state.shopLocalisations.filter(
          (localisation) => localisation.id !== action.payload
        ),
      }
    }
    case 'DELETED_SHOP_LOCALISATION_ERROR': {
      return {
        ...state,
        localisationLoading: false,
        error: action.payload,
      }
    }
    case 'FETCH_SUPPLIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'RECEIVE_SUPPLIES': {
      return {
        ...state,
        isFetching: false,
        supplies: action.payload,
      }
    }
    case 'FETCHING_SHOP_INCENTIVE_PROGRAMS': {
      return {
        ...state,
        incentivePrograms: {
          total: 0,
          list: [],
          isFetching: true,
        },
      }
    }
    case 'RECEIVE_SHOP_INCENTIVE_PROGRAMS': {
      return {
        ...state,
        incentivePrograms: {
          isFetching: false,
          total: action.payload.total,
          list: action.payload.data,
        },
      }
    }
    case 'FETCH_SHOP_INCENTIVE_PROGRAMS_ERROR': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
        },
        error: action.payload,
      }
    }
    case 'UPDATING_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: true,
        },
      }
    }
    case 'UPDATED_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
          list: state.incentivePrograms.list.map((prog) =>
            prog.id === action.payload.id ? action.payload : prog
          ),
        },
      }
    }
    case 'UPDATING_SHOP_INCENTIVE_PROGRAM_ERROR': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
        },
        error: action.payload,
      }
    }
    case 'CREATING_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: true,
        },
      }
    }
    case 'CREATED_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
          list: [action.payload, ...state.incentivePrograms.list],
          total: state.incentivePrograms.total + 1,
        },
      }
    }
    case 'CREATING_SHOP_INCENTIVE_PROGRAM_ERROR': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
        },
        error: action.payload,
      }
    }
    case 'DELETING_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: true,
        },
      }
    }
    case 'DELETED_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
          list: state.incentivePrograms.list.filter((prog) => prog.id !== action.payload),
          total: state.incentivePrograms.total - 1,
        },
      }
    }
    case 'DELETING_INCENTIVE_PROGRAM_ERROR': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
        },
        error: action.payload,
      }
    }
    case 'STOPPING_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: true,
        },
      }
    }
    case 'STOPPED_SHOP_INCENTIVE_PROGRAM': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
          list: state.incentivePrograms.list.map((prog) =>
            prog.id === action.payload.id ? action.payload : prog
          ),
        },
      }
    }
    case 'STOPPING_INCENTIVE_PROGRAM_ERROR': {
      return {
        ...state,
        incentivePrograms: {
          ...state.incentivePrograms,
          isFetching: false,
        },
        error: action.payload,
      }
    }
    case 'FETCHING_SHOP_INCENTIVE_INVOICES': {
      return {
        ...state,
        incentiveInvoicesLoading: true,
      }
    }
    case 'RECEIVE_SHOP_INCENTIVE_INVOICES': {
      return {
        ...state,
        incentiveInvoicesLoading: false,
        incentiveInvoices: action.payload,
      }
    }
    case 'FETCH_SHOP_INCENTIVE_INVOICES_ERROR': {
      return {
        ...state,
        incentiveInvoicesLoading: false,
        error: action.payload,
      }
    }
    case 'FETCHING_SHOP_SUBSCRIPTION': {
      return {
        ...state,
        subscriptionFetching: true,
      }
    }
    case 'RECEIVE_SHOP_SUBSCRIPTION': {
      return {
        ...state,
        subscriptionFetching: false,
        subscription: action.payload,
      }
    }
    case 'FETCHING_SHOP_SUBSCRIPTION_ERROR': {
      return {
        ...state,
        subscriptionFetching: false,
        error: action.payload,
      }
    }
    case 'UPDATING_SHOP_SUBSCRIPTION': {
      return {
        ...state,
      }
    }
    case 'UPDATED_SHOP_SUBSCRIPTION': {
      return {
        ...state,
        subscription: action.payload,
      }
    }
    case 'UPDATING_SHOP_SUBSCRIPTION_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'ADDING_SUBSCRIPTION_PRODUCT': {
      return {
        ...state,
      }
    }

    case 'ADDED_SUBSCRIPTION_PRODUCT': {
      return {
        ...state,
        subscription: action.payload,
      }
    }
    case 'ADDING_SUBSCRIPTION_PRODUCT_ERROR': {
      return {
        ...state,
      }
    }
    case 'DELETING_SUBSCRIPTION_PRODUCT': {
      return {
        ...state,
      }
    }

    case 'DELETED_SUBSCRIPTION_PRODUCT': {
      return {
        ...state,
        subscription: action.payload,
      }
    }
    case 'DELETING_INVOICE_PRODUCT_ERROR': {
      return {
        ...state,
      }
    }
    case 'UPDATING_SHOP_INFORMATIONS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'UPDATED_SHOP_INFORMATIONS': {
      return {
        ...state,
        isFetching: false,
        shop: { ...state.shop, ...action.payload },
      }
    }
    case 'UPDATING_SHOP_INFORMATIONS_ERROR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    case 'FETCHING_SHOP_PARAMETERS': {
      return {
        ...state,
        parametersLoading: true,
      }
    }
    case 'RECEIVE_SHOP_PARAMETERS': {
      return {
        ...state,
        parametersLoading: false,
        parameters: action.payload,
      }
    }
    case 'FETCH_SHOP_PARAMETERS_ERROR': {
      return {
        ...state,
        localisationLoading: false,
        error: action.payload,
      }
    }
    case 'UPDATING_SHOP_PARAMETERS': {
      return {
        ...state,
        localisationLoading: true,
      }
    }
    case 'UPDATED_SHOP_PARAMETERS': {
      return {
        ...state,
        localisationLoading: false,
      }
    }
    case 'UPDATING_SHOP_PARAMETERS_ERROR': {
      return {
        ...state,
        localisationLoading: false,
      }
    }

    case 'FETCHING_OFFERS': {
      return {
        ...state,
        offers: {
          isFetching: true,
          list: [],
          total: 0,
        },
      }
    }

    case 'FETCH_SHOP_OFFERS_ERROR': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
          list: [],
          total: 0,
        },
      }
    }

    case 'RECEIVE_SHOP_OFFERS': {
      return {
        ...state,
        offers: {
          isFetching: false,
          list: action.payload.data,
          total: action.payload.total,
        },
      }
    }

    case 'CREATING_SHOP_OFFERS': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: true,
        },
      }
    }
    case 'CREATED_SHOP_OFFERS': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: true,
        },
      }
    }
    case 'CREATING_SHOP_OFFERS_ERROR': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
        },
      }
    }

    case 'UPDATING_SHOP_OFFERS': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: true,
        },
      }
    }

    case 'UPDATED_SHOP_OFFERS': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
        },
      }
    }
    case 'UPDATING_SHOP_OFFERS_ERROR': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
        },
      }
    }
    case 'DELETING_SHOP_OFFER': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: true,
        },
      }
    }
    case 'DELETED_SHOP_OFFER': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
          list: state.offers.list.filter((offer) => offer.id !== action.payload),
          total: state.offers.total - 1,
        },
      }
    }
    case 'DELETED_SHOP_OFFER_ERROR': {
      return {
        ...state,
        offers: {
          ...state.offers,
          isFetching: false,
        },
      }
    }
    case 'FETCHING_SHOP_GIFTS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_SHOP_GIFTS': {
      return {
        ...state,
        isFetching: false,
        shopGifts: action.payload.data,
      }
    }

    case 'FETCH_SHOP_GIFTS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    case 'CREATING_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: true,
      }
    }
    case 'CREATED_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: false,
        shopGifts: [action.payload, ...state.shopGifts],
      }
    }
    case 'CREATING_SHOP_GIFT_ERROR': {
      return {
        ...state,
        giftsLoading: false,
        error: action.payload,
      }
    }
    case 'UPDATING_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: true,
      }
    }
    case 'UPDATED_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: false,
        shopGifts: state.shopGifts.map((gift) =>
          gift.id === action.payload.id ? action.payload : gift
        ),
      }
    }
    case 'UPDATING_SHOP_GIFT_ERROR': {
      return {
        ...state,
        giftsLoading: false,
        error: action.payload,
      }
    }
    case 'DELETING_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: true,
      }
    }
    case 'DELETED_SHOP_GIFT': {
      return {
        ...state,
        giftsLoading: false,
        shopGifts: state.shopGifts.filter((gift) => gift.id !== action.payload),
      }
    }
    case 'DELETED_SHOP_GIFT_ERROR': {
      return {
        ...state,
        giftsLoading: false,
        error: action.payload,
      }
    }
    case 'SETTING_SHOP_GIFTS_TO_SUPPLY': {
      return {
        ...state,
        giftsLoading: true,
      }
    }
    case 'SET_SHOP_GIFTS_TO_SUPPLY': {
      return {
        ...state,
        giftsLoading: false,
        offers: {
          ...state.offers,
          list: state.offers.list.map((offer) =>
            offer.id === action.payload.id ? action.payload : offer
          ),
        },
      }
    }
    case 'SETTING_SHOP_GIFTS_TO_SUPPLY_ERROR': {
      return {
        ...state,
        giftsLoading: false,
        error: action.payload,
      }
    }
    case 'FETCHING_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedulesLoading: true,
      }
    }
    case 'RECEIVE_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedulesLoading: false,
        reportingSchedules: action.payload,
      }
    }
    case 'FETCH_SHOP_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
        reportingSchedulesLoading: false,
        error: action.payload.data,
      }
    }
    case 'UPDATING_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedulesLoading: true,
      }
    }
    case 'UPDATED_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedules: {
          ...state.reportingSchedules,
          data: state.reportingSchedules.data.map((report) =>
            report.id === action.payload.id ? action.payload : report
          ),
        },
        reportingSchedulesLoading: false,
      }
    }
    case 'UPDATING_SHOP_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
        reportingSchedulesLoading: false,
      }
    }
    case 'CREATING_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedulesLoading: true,
      }
    }
    case 'CREATED_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedules: {
          ...state.reportingSchedules,
          data: [...state.reportingSchedules.data, action.payload],
        },
        reportingSchedulesLoading: false,
      }
    }
    case 'CREATING_SHOP_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
        reportingSchedulesLoading: false,
      }
    }
    case 'DELETING_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedulesLoading: true,
      }
    }
    case 'DELETED_SHOP_REPORTING_SCHEDULE': {
      return {
        ...state,
        reportingSchedules: {
          ...state.reportingSchedules,
          data: state.reportingSchedules.data.filter((report) => report.id !== action.payload),
        },
        reportingSchedulesLoading: false,
      }
    }
    case 'DELETING_SHOP_REPORTING_SCHEDULE_ERROR': {
      return {
        ...state,
        reportingSchedulesLoading: false,
      }
    }
    case 'FETCHING_SHOP_STATS': {
      return {
        ...state,
        isFetchingStats: true,
      }
    }
    case 'FETCH_SHOP_STATS': {
      return {
        ...state,
        stats: action.payload,
        isFetchingStats: false,
      }
    }
    case 'FETCHING_SHOP_STATS_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'FETCHING_LOCALISATION': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'RECEIVE_LOCALISATION': {
      return {
        ...state,
        isFetching: false,
        localisation: action.payload,
      }
    }
    case 'FETCHING_LOCALISATION_ERROR': {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }
    }
    case 'FETCHING_LOCALISATIONS': {
      return {
        ...state,
        localisations: {
          isFetching: true,
          list: [],
          total: 0,
        },
      }
    }
    case 'RECEIVE_LOCALISATIONS': {
      return {
        ...state,
        localisations: {
          isFetching: false,
          list: action.payload.data,
          total: action.payload.total,
        },
      }
    }
    case 'FETCHING_LOCALISATIONS_ERROR': {
      return {
        ...state,
        error: action.payload,
        localisations: {
          ...state.users,
          isFetching: false,
        },
      }
    }
    case 'FETCHING_SHOP_USERS': {
      return {
        ...state,
        users: {
          isFetching: true,
          list: [],
          total: 0,
        },
      }
    }
    case 'RECEIVE_SHOP_USERS': {
      return {
        ...state,
        users: {
          isFetching: false,
          list: action.payload.data,
          total: action.payload.total,
        },
      }
    }
    case 'FETCH_SHOP_USERS_ERROR': {
      return {
        ...state,
        error: action.payload,
        users: {
          ...state.users,
          isFetching: false,
        },
      }
    }
    case 'UPDATED_SHOP_SUBSCRIPTION_AUTO_UPDATE': {
      return {
        ...state,
        shop: { ...state.shop, auto_update_subscription: action.payload },
      }
    }
    default: {
      return state
    }
  }
}

export default shopReducer
