interface IReducer {
  list: any[]
  equipmentHistory: any
  isFetching: boolean
  isFetchingHistory: boolean
  isFetchingMoreHistory: boolean
  loader: boolean
  error: any
  filters: any[]
  total: number
}

const initialState: IReducer = {
  list: [],
  equipmentHistory: null,
  isFetching: false,
  isFetchingHistory: false,
  isFetchingMoreHistory: false,
  loader: false,
  error: null,
  filters: [],
  total: 0,
}

const equipmentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'CREATING':
    case 'ASSIGNING':
    case 'RETURNING':
    case 'DELETING':
    case 'EDITING': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'FETCHING_EQUIPMENTS':
    case 'FETCHING_EQUIPMENTS_BY_SHOP_ID':
    case 'FETCHING_EQUIPMENTS_BY_LOC_ID': {
      return {
        ...state,
        isFetching: true,
        list: [],
      }
    }

    case 'FETCHING_HISTORY': {
      return {
        ...state,
        isFetchingHistory: true,
      }
    }
    case 'FETCHING_MORE_HISTORY': {
      return {
        ...state,
        isFetchingMoreHistory: true,
      }
    }

    case 'EQUIPMENT_CREATE_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        total: state.total + 1,
        list: [action.payload, ...state.list],
      }
    }

    case 'EQUIPMENT_CREATE_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'EQUIPMENT_RETURN_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((equipment) =>
          equipment.id === action.payload.id ? action.payload : equipment
        ),
      }
    }

    case 'EQUIPMENT_RETURN_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'EQUIPMENT_DELETE_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((equipment) =>
          equipment.id === action.payload.id ? action.payload : equipment
        ),
      }
    }

    case 'EQUIPMENT_DELETE_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'EQUIPMENT_ASSIGN_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((equipment) =>
          equipment.id === action.payload.id ? action.payload : equipment
        ),
      }
    }

    case 'EQUIPMENT_ASSIGN_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'EQUIPMENT_EDIT_RESPONSE': {
      return {
        ...state,
        isFetching: false,
        list: state.list.map((equipment) =>
          equipment.id === action.payload.id ? action.payload : equipment
        ),
      }
    }

    case 'EQUIPMENT_EDIT_ERR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'FETCH_EQUIPMENTS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'RECEIVE_EQUIPMENTS': {
      return {
        ...state,
        isFetching: false,
        total: action.payload.total,
        list: action.payload.data,
      }
    }

    case 'FETCH_EQUIPMENT_HISTORY_ERR': {
      return {
        ...state,
        isFetching: false,
        isFetchingHistory: false,
        error: action.payload,
      }
    }

    case 'CLEANING_HISTORY': {
      return {
        ...state,
        equipmentHistory: null,
      }
    }

    case 'RECEIVE_EQUIPMENT_HISTORY': {
      let oldData = []
      if (state.equipmentHistory && state.equipmentHistory.data) {
        oldData = state.equipmentHistory.data
      }

      const newHistoryData = {
        data: [...oldData, ...action.payload.data],
      }

      return {
        ...state,
        isFetching: false,
        isFetchingHistory: false,
        isFetchingMoreHistory: false,
        equipmentHistory: { ...action.payload, ...newHistoryData },
      }
    }

    default: {
      return state
    }
  }
}

export default equipmentsReducer
