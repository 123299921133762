import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { datadogLogs } from '@datadog/browser-logs'
import MainRouter from './components/Routes/MainRouter'
import ReduxStore from './redux/Store'
import './sass/main.sass'
import { checkRefreshTokenStatus, checkSession, cleanUserLog } from './components/helper'
import Config from './Config'
import reportWebVitals from './reportWebVitals'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import 'dayjs/locale/tr'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(isSameOrBefore)
dayjs.extend(LocalizedFormat)
dayjs.extend(CustomParseFormat)

dayjs.locale(navigator.language.slice(0, 2))

checkRefreshTokenStatus()
checkSession()

datadogLogs.init({
  clientToken: Config.datadog_key,
  datacenter: 'us',
  forwardErrorsToLogs: true,
  sampleRate: 100,
})

datadogLogs.setLoggerGlobalContext({
  service: 'lh_admin',
  host: window.location.hostname,
  user: cleanUserLog(),
})

ReactDOM.render(
  <Provider store={ReduxStore}>
    <MainRouter />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
