interface IReducer {
  featureFlags: any[]
  isFetchingFeatureFlags: boolean
  error: any
  totalFeatureFlags: number
}

const initialState: IReducer = {
  featureFlags: [],
  isFetchingFeatureFlags: false,
  totalFeatureFlags: null,
  error: null,
}

const toolsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_FEATURE_FLAGS': {
      return {
        ...state,
        featureFlags: [],
        isFetchingFeatureFlags: true,
      }
    }

    case 'RECEIVE_FEATURE_FLAGS': {
      return {
        ...state,
        isFetchingFeatureFlags: false,
        totalFeatureFlags: action.payload.total,
        featureFlags: action.payload.data,
      }
    }

    case 'FETCH_FEATURE_FLAGS_ERROR': {
      return {
        ...state,
        isFetchingFeatureFlags: false,
        error: action.payload,
      }
    }
    case 'UPDATING_FEATURE_FLAG': {
      return {
        ...state,
        isFetchingFeatureFlags: true,
      }
    }
    case 'UPDATED_FEATURE_FLAG': {
      return {
        ...state,
        isFetchingFeatureFlags: false,
        featureFlags: state.featureFlags.map((flag) =>
          flag.id === action.payload ? { ...flag, is_enabled: !flag.is_enabled } : flag
        ),
      }
    }
    case 'UPDATING_FEATURE_FLAG_ERROR': {
      return {
        ...state,
        isFetchingFeatureFlags: false,
      }
    }
    default: {
      return state
    }
  }
}

export default toolsReducer
