interface IReducer {
  subscriptions: any[]
  isFetching: boolean
  error: any
}

const initialState: IReducer = {
  subscriptions: [],
  isFetching: false,
  error: null,
}

const subscriptionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'RECEIVE_SUBSCRIPTIONS': {
      return {
        ...state,
        isFetching: false,
        subscriptions: action.payload.data,
      }
    }
    case 'FETCH_SUBSCRIPTIONS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default subscriptionReducer
