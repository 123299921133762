import React from 'react'
import styled from 'styled-components'

const StyledLoader = styled.div`
  .loading-wheel {
    position: ${(props) => (props.isRelative ? 'relative' : 'fixed')} !important;
    width: ${(props) => (props.small ? '50px' : '75px')} !important;
    height: ${(props) => (props.small ? '50px' : '75px')} !important;
    z-index: 10;

    &:before {
      background-image: linear-gradient(
          ${(props) => props.bgColor || '#F7F7F7'},
          ${(props) => props.bgColor || '#F7F7F7'}
        ),
        linear-gradient(#ebba1b, #ebba1b), linear-gradient(#ebba1b, #ebba1b),
        linear-gradient(#ebba1b, #ebba1b) !important;
    }

    &:after {
      background: ${(props) => props.bgColor || '#F7F7F7'} !important;
    }
  }

  #lottie {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    text-align: center;
    opacity: 1;
  }
`

export const LoadingWheel = (props: {
  isRelative?: boolean
  bgColor?: string
  small?: boolean
}) => {
  return (
    <StyledLoader {...props}>
      <div className="loading-wheel">
        <div id="lottie"></div>
      </div>
    </StyledLoader>
  )
}

export default LoadingWheel
