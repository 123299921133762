import React from 'react'
import { Redirect } from 'react-router'
import SubMenu from './SubMenu'
import { ISubMenuLink } from './ISubMenuLink'
import { canEditUser, canViewUser } from '../../utils/roles'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const SubMenuUser: React.FunctionComponent<WrappedComponentProps> = ({ intl }) => {
  let userId = 0

  if (window.location.href.match(/\/user\/.*\/(\d+)/)) {
    userId = +window.location.href.match(/\/user\/.*\/(\d+)/)[1]
  } else {
    return <Redirect to="/users" />
  }

  const subMenuLinks: ISubMenuLink[] = [
    {
      name: intl.formatMessage({ id: 'user.navbar.account' }),
      url: `/user/account/${userId}`,
      isRoute: true,
      authorized: canEditUser(),
    },
    {
      name: intl.formatMessage({ id: 'user.navbar.notifications' }),
      url: `/user/notifications/${userId}`,
      isRoute: true,
      authorized: canEditUser(),
    },
    {
      name: intl.formatMessage({ id: 'user.navbar.wallets' }),
      url: `/user/wallets/${userId}`,
      isRoute: true,
      authorized: canViewUser(),
    },
  ]

  return <SubMenu subMenuLinks={subMenuLinks} />
}

export default injectIntl(SubMenuUser)
