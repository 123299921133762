interface IReducer {
  shipments: any[]
  errors: any[]
  isFetching: boolean
}

const initialState: IReducer = {
  shipments: [],
  errors: [],
  isFetching: false,
}

const shipmentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_SHIPMENTS': {
      return {
        ...state,
        isFetching: true,
      }
    }
    case 'RECEIVE_SHIPMENTS': {
      return {
        ...state,
        isFetching: false,
        shipments: action.payload,
      }
    }
    case 'FETCHING_SHIPMENTS_ERR': {
      return {
        ...state,
        isFetching: false,
      }
    }
    case 'CREATING_SHIPMENT': {
      return {
        ...state,
      }
    }
    case 'CREATED_SHIPMENT': {
      return {
        ...state,
        shipments: [action.payload, ...state.shipments],
      }
    }
    case 'CREATING_SHIPMENT_ERR': {
      return {
        ...state,
      }
    }
    default: {
      return state
    }
  }
}

export default shipmentReducer
