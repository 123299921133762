interface IReducer {
  supplies: any[]
  gifts: any[]
  isFetching: boolean
  error: any
}

const initialState: IReducer = {
  supplies: [],
  gifts: [],
  isFetching: false,
  error: null,
}

const suppliesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCHING_SUPPLIES': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_SUPPLIES': {
      return {
        ...state,
        isFetching: false,
        supplies: action.payload.data,
      }
    }

    case 'FETCH_SUPPLIES_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }

    case 'FETCHING_GIFTS': {
      return {
        ...state,
        isFetching: true,
      }
    }

    case 'RECEIVE_GIFTS': {
      return {
        ...state,
        isFetching: false,
        gifts: action.payload.data,
      }
    }

    case 'FETCH_GIFTS_ERROR': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      }
    }
    default:
      return state
  }
}

export default suppliesReducer
