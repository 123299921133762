import React from 'react'
import { Redirect } from 'react-router'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import SubMenu from './SubMenu'
import { ISubMenuLink } from './ISubMenuLink'
import { canEditShop, canViewShop, isAccountant } from '../../utils/roles'

interface IProps extends WrappedComponentProps {
  subMenuLinks: ISubMenuLink[]
}

const SubMenuShop: React.FunctionComponent<IProps> = (props: IProps) => {
  const { intl } = props

  let shopId = 0

  if (window.location.href.match(/\/shop\/.*\/(\d+)/)) {
    shopId = +window.location.href.match(/\/shop\/.*\/(\d+)/)[1]
  } else {
    return <Redirect to="/shops" />
  }

  const subMenuLinks: ISubMenuLink[] = [
    {
      name: intl.formatMessage({ id: 'shop.navbar.account' }),
      url: `/shop/account/${shopId}`,
      isRoute: true,
      authorized: canViewShop(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.localisation' }),
      url: `/shop/localisations/${shopId}`,
      isRoute: true,
      authorized: canViewShop(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.offers' }),
      url: `/shop/offers/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.informations' }),
      url: `/shop/informations/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.users' }),
      url: `/shop/users/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.news' }),
      url: `/shop/news/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.parameters' }),
      url: `/shop/parameters/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.subscription' }),
      url: `/shop/subscription/${shopId}`,
      isRoute: true,
      authorized: canEditShop(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.invoices' }),
      url: `/shop/invoices/${shopId}`,
      isRoute: true,
      authorized: canViewShop(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.ecommerce' }),
      url: `/shop/ecommerces/${shopId}`,
      isRoute: true,
      authorized: canViewShop() && !isAccountant(),
    },
    //{
    //   name: intl.formatMessage({ id: 'shop.navbar.incentive' }),
    //   url: `/shop/incentive/${shopId}`,
    //   isRoute: true,
    //   authorized: canViewShop() && !isAccountant(),
    //},
    {
      name: intl.formatMessage({ id: 'shop.navbar.shipment' }),
      url: `/shop/shipment/${shopId}`,
      isRoute: true,
      authorized: canEditShop(),
    },
    {
      name: intl.formatMessage({ id: 'shop.navbar.reports' }),
      url: `/shop/reports/${shopId}`,
      isRoute: true,
      authorized: canViewShop(),
    },
  ]

  return <SubMenu subMenuLinks={subMenuLinks} />
}

export default injectIntl(SubMenuShop)
