import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import CurrentLocale from '../currentLocale'
import FR from '../../translations/fr.json'
import TR from '../../translations/tr.json'
import LoadingWheel from '../LoadingWheel'
import App from '../App'
import { LhCookiesConsentManager } from '@leshabitues/leshabitues-ui'
import * as gtag from '../../utils/gtag'
import { flattenMessages } from '../../utils'
import { ConfigProvider } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import trTR from 'antd/lib/locale/tr_TR'

const AntdLocales = {
  fr: frFR,
  tr: trTR,
}

const messages = {
  fr: FR,
  tr: TR,
}

const MainRouter = (props) => {
  return (
    <Router>
      <IntlProvider
        locale={navigator.language}
        messages={
          messages[navigator.language.slice(0, 2)]
            ? flattenMessages(messages[navigator.language.slice(0, 2)])
            : flattenMessages(messages['fr'])
        }
      >
        <Suspense fallback={<LoadingWheel />}>
          <LhCookiesConsentManager
            apps={[
              {
                name: 'googleAnalytics',
                purposes: ['analytics'],
                cookies: [
                  [/^_ga/, '/', 'dashboard.leshabitues.fr'],
                  [/^_gat/, '/', 'dashboard.leshabitues.fr'],
                  [/^_gid/, '/', 'dashboard.leshabitues.fr'],
                  [/^_gat_.*$/, '/', 'dashboard.leshabitues.fr'],
                  [/^_ga/, '/', 'localhost'],
                  [/^_gat/, '/', 'localhost'],
                  [/^_gid/, '/', 'localhost'],
                  [/^_gat_.*$/, '/', 'localhost'],
                ],
                onDecline: () => {
                  gtag.consent(false, false)
                },
                onInit: () => {
                  gtag.init()
                },
                onAccept: () => {
                  gtag.consent(true, true)
                },
              },
            ]}
            privacyPolicyUrl="https://www.leshabitues.fr/terms/cgu"
            editButtonStyle={{
              zIndex: 10,
            }}
          />
          <CurrentLocale />
          <ConfigProvider locale={AntdLocales[navigator.language.slice(0, 2)]}>
            <App />
          </ConfigProvider>
        </Suspense>
      </IntlProvider>
    </Router>
  )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(MainRouter)
